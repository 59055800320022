@import '../../../../scss/theme-bootstrap';

// Video version of same template
.content-block-large--video {
  max-width: $max-width-large;
  margin: auto;
  .content-block-large__image-trigger {
    display: block;
    position: relative;
    &:hover {
      text-decoration: none;
    }
  }
}

// Colorbox override
.overlay--content-block-video {
  @include transform(translateY(-100%));
  @include transition(transform 1.4s);
  &.active {
    @include transform(translateY(0));
  }
  #cboxContent {
    background-color: $black;
    margin-top: 0;
  }
  #cboxLoadedContent {
    padding: 45px 0 0 0;
    background: $black;
    @include breakpoint($med-small-up) {
      margin: 0 auto;
      padding: 0;
      max-width: 84%;
    }
  }
  #cboxClose {
    min-width: auto;
    right: 10px;
    @include breakpoint($portrait-up) {
      right: 19px;
    }
    &:before {
      background-color: $white;
      width: 22px;
      height: 22px;
      @include breakpoint($landscape-up) {
        width: 32px;
        height: 32px;
      }
    }
  }
  iframe {
    display: block;
  }
  video {
    @include transform(translateY(-50%));
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    top: 50%;
  }
}
